import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { EventIncludeOption, EventService } from "../../../../services/event.service";
import { jSith } from "../../../../util/jquery-replacement";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { S25Datefilter } from "../../../s25-dateformat/s25.datefilter.service";
import { PricingService } from "../../../../services/pricing.service";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { Bind } from "../../../../decorators/bind.decorator";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { TelemetryService } from "../../../../services/telemetry.service";
import { LineItem } from "../../../../pojo/Pricing";
import { S25Const } from "../../../../util/s25-const";

@TypeManagerDecorator("s25-ng-pricing-set")
@Component({
    selector: "s25-ng-pricing-set",
    template: `@if (initModal) {
        <div>
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            @if (initData) {
                <div>
                    <div class="pricing-set--name-eventType c-margin-top--single">
                        <h2>Name and Event Type</h2>
                        <div class="c-margin-top--single">
                            <label for="pricing-name"
                                >Name
                                <input
                                    class="c-input c-margin-bottom--half c-margin-left--half"
                                    id="pricing-name"
                                    [ngClass]="{ 'error-outline': errorOutline }"
                                    [(ngModel)]="name"
                                />
                                <span class="required">*</span>
                            </label>
                            <s25-ng-checkbox
                                [(modelValue)]="includeEventType"
                                [labelId]="'pricingEventType'"
                                [containerClass]="'pricing-set-eventType'"
                                >Include Event Type {{ eventTypeBilled ? "(already billed)" : "" }}</s25-ng-checkbox
                            >
                        </div>
                    </div>
                    @if (rsrvTable?.length) {
                        <div class="pricing-set--occs c-margin-top--single">
                            <s25-simple-collapse [headerText]="'Occurrences'" [defaultCollapsed]="true">
                                <div class="date-range-wrapper">
                                    <div>Date Range Applied</div>
                                    <div>
                                        <div class="date-range-dropdown">
                                            <button
                                                class="aw-button aw-button--primary"
                                                [ngClass]="{ 'date-range-button': showSelectDateRange }"
                                                (click)="toggleShowDateRange()"
                                            >
                                                {{
                                                    selectedDateRange.selectionMade || showSelectDateRange
                                                        ? selectedDateRange.placeholderStart +
                                                          " - " +
                                                          selectedDateRange.placeholderEnd
                                                        : "None"
                                                }}
                                            </button>
                                            <button
                                                class="aw-button aw-button--primary"
                                                (click)="toggleShowDateRange()"
                                            >
                                                <svg class="c-svgIcon" role="img">
                                                    <title>Expand</title>
                                                    <use
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-down"
                                                    ></use>
                                                </svg>
                                            </button>
                                        </div>
                                        @if (showSelectDateRange) {
                                            <div class="date-picker-wrapper">
                                                @if (dateRangeErrorMsg) {
                                                    <div class="error-message">{{ dateRangeErrorMsg }}</div>
                                                }
                                                <div class="date-pickers">
                                                    <s25-datepicker
                                                        [modelValue]="{ date: selectedDateRange.start }"
                                                        [inline]="true"
                                                        [autoSelectToday]="false"
                                                        (modelValueChange)="updateDateRange($event, 'start')"
                                                    ></s25-datepicker>
                                                    <s25-datepicker
                                                        [modelValue]="{ date: selectedDateRange.end }"
                                                        [inline]="true"
                                                        [autoSelectToday]="false"
                                                        (modelValueChange)="updateDateRange($event, 'end')"
                                                    ></s25-datepicker>
                                                </div>
                                                <div class="date-picker-buttons">
                                                    <button
                                                        (click)="applyDates()"
                                                        class="aw-button aw-button--primary apply-button"
                                                    >
                                                        Apply
                                                    </button>
                                                    <button (click)="clearDates()" class="aw-button aw-button--outline">
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <s25-ng-table
                                    [caption]="'Pricing Set Occurrences'"
                                    [dataSource]="occTableConfig"
                                    [hasSelect]="true"
                                    [hasSelectAll]="true"
                                    [align]="'center'"
                                    [selected]="selectedIds"
                                    [pivotThreshold]="1000"
                                    (selectedChange)="include($event, 'rsrv')"
                                ></s25-ng-table>
                            </s25-simple-collapse>
                        </div>
                    }
                    @if (requirementTable?.length) {
                        <div class="pricing-set--reqs c-margin-top--single">
                            <s25-simple-collapse [headerText]="'Requirements'" [defaultCollapsed]="false">
                                <s25-ng-table
                                    [dataSource]="reqTableConfig"
                                    [caption]="'Pricing Set Requirements'"
                                    [hasSelect]="true"
                                    [hasSelectAll]="true"
                                    [align]="'center'"
                                    [pivotThreshold]="700"
                                    (selectedChange)="include($event, 'req')"
                                ></s25-ng-table>
                            </s25-simple-collapse>
                        </div>
                    }
                    <s25-ng-modal-footer [content]="s25ModalFooter"></s25-ng-modal-footer>
                    <ng-template #s25ModalFooter>
                        <div class="footerContainer">
                            @if (errorMessage) {
                                <div>{{ errorMessage }}</div>
                            }
                            <button
                                #submitButton
                                class="aw-button aw-button--primary c-margin-top--single c-margin-bottom--single"
                                (click)="createPricingSet()"
                                [disabled]="isCreatingPricingSet"
                            >
                                {{ isCreatingPricingSet ? "" : "Create Invoice" }}
                                <s25-loading-inline [model]="{}"></s25-loading-inline>
                            </button>
                        </div>
                    </ng-template>
                </div>
            }
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingSetComponent implements OnInit {
    @Input() modelBean: any;
    @Input() optBean: any;

    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("submitButton") submitButton: ElementRef;
    @ViewChild(S25TableComponent) occTable: S25TableComponent;

    initModal: boolean;
    initData: boolean;
    includeEventType: boolean = false;
    eventTypeBilled: boolean = false;
    requirementTable: any[];
    rsrvTable: any[];
    occTableConfig: Table.DataSource;
    reqTableConfig: Table.DataSource;
    multiEvents: boolean;
    multiProfiles: boolean;
    name: string;
    isCreatingPricingSet: boolean = false;
    errorMessage: string;
    errorOutline: boolean;
    showSelectDateRange: boolean;
    dateRangeErrorMsg: string;
    selectedDateRange: {
        start: Date | string | null;
        end: Date | string | null;
        placeholderStart: string | null;
        placeholderEnd: string | null;
        selectionMade: boolean;
    };
    selectedIds: Set<number | string>;

    occTableColumns: Table.Column[] = [
        { id: "alreadyBilled", header: "Already Billed" },
        { id: "name", header: "Name" },
        { id: "title", header: "Title" },
        { id: "start", header: "Start" },
        { id: "end", header: "End" },
        { id: "comment", header: "Comment" },
        { id: "locations", header: "Locations" },
        { id: "resources", header: "Resources" },
    ];

    reqTableColumns: Table.Column[] = [
        { id: "isBilled", header: "Already Billed" },
        { id: "reqName", header: "Name" },
    ];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.initModal = true;
        this.cd.detectChanges();
        S25LoadingApi.init(this.elementRef.nativeElement);

        this.clearDates();

        const includes: EventIncludeOption[] = ["profile", "reservations", "requirements"];
        const eventData = this.optBean?.combineRelatedEvents
            ? await EventService.getEventAndRelated(this.modelBean.pricingSet.itemId, includes)
            : await EventService.getEventsInclude(this.modelBean.pricingSet.itemId, includes);

        let rsrvTable: any[] = [],
            requirementTable: any[] = [];

        this.multiEvents = eventData?.length > 1;
        this.multiProfiles = false;

        const pricingSetRsrvMap: any = {},
            pricingSetReqMap: any = {};

        jSith.forEach(this.modelBean.pricingSets, (_, pricingSet) => {
            if (!pricingSet.includeSpaces && !pricingSet.includeResources) {
                //ANG-5316 if no occurrences selected for an invoice, need to iterate through actual occurrence data for each line item to determine billed status - the reservation list in billDefn > reservations will show all occurrences when no reservations added, "https://collegenet.atlassian.net/wiki/spaces/25SERVICES/pages/1867852/POST+event+billingCustom.+e+xml+json"
                pricingSet?.billing?.lineItems.forEach((lineItem: LineItem) => {
                    if (!!lineItem.occurrences && lineItem.occurrences.length && lineItem.itemType !== 1) {
                        lineItem.occurrences.forEach((occurrence) => {
                            const rsrvId = occurrence.rsrvId;
                            if (!pricingSetRsrvMap[rsrvId]) pricingSetRsrvMap[rsrvId] = [];
                            pricingSetRsrvMap[rsrvId].push(pricingSet.billDefn.billName);
                        });
                    }
                });
            } else {
                jSith.forEach(pricingSet.billDefn.reservations, (_, rsrv) => {
                    const rsrvId = parseInt(rsrv.rsrv_id);
                    if (!pricingSetRsrvMap[rsrvId]) pricingSetRsrvMap[rsrvId] = [];
                    pricingSetRsrvMap[rsrvId].push(pricingSet.billDefn.billName);
                });
            }

            jSith.forEach(pricingSet.billDefn.requirements, (_, req) => {
                const reqId = parseInt(req.requirementId);
                if (!pricingSetReqMap[reqId]) pricingSetReqMap[reqId] = [];
                pricingSetReqMap[reqId].push(pricingSet.billDefn.billName);
            });

            this.eventTypeBilled ||= S25Util.toBool(pricingSet.billDefn.includeEventType);
        });

        jSith.forEach(eventData, (_, event) => {
            const eventId = parseInt(event.event_id),
                eventLocator = event.event_locator,
                eventName = event.event_name,
                eventTitle = event.event_title ?? "";

            this.multiProfiles ||= event.profile?.length > 1;

            jSith.forEach(event.profile, (_, profile) => {
                const profileId = parseInt(profile.profile_id),
                    profileName = S25Util.profileName(profile.profile_name?.toString()) ?? "",
                    profileComment = profile.profile_comments ?? "";

                jSith.forEach(profile.reservation, (_, rsrv) => {
                    const rsrvId = parseInt(rsrv.reservation_id),
                        isBilled = !!pricingSetRsrvMap[rsrvId],
                        rsrvStartDate = S25Datefilter.transform(
                            rsrv.reservation_start_dt,
                            this.modelBean.dateTimeFormat,
                        ),
                        rsrvEndDate = S25Datefilter.transform(rsrv.reservation_end_dt, this.modelBean.dateTimeFormat),
                        rsrvComment = rsrv.rsrv_comments ?? "";

                    let locations: string[] = [],
                        resources: string[] = [];

                    jSith.forEach(rsrv.space_reservation, (_, spRsrv) => {
                        if (!S25Util.isPrivate(spRsrv.space?.space_name)) locations.push(spRsrv.space.space_name);
                    });

                    jSith.forEach(rsrv.resource_reservation, (_, rsRsrv) => {
                        if (!S25Util.isPrivate(rsRsrv.resource?.resource_name))
                            resources.push(rsRsrv.resource.resource_name);
                    });

                    locations = S25Util.array.unique(locations);
                    resources = S25Util.array.unique(resources);

                    rsrvTable.push({
                        eventId,
                        profileId,
                        rsrvId,
                        isBilled: isBilled ? "Yes" : "No",
                        eventLocator,
                        eventName,
                        eventTitle,
                        profileName,
                        profileComment,
                        rsrvStartDate,
                        rsrvEndDate,
                        rsrvComment,
                        locations: locations.join(", "),
                        resources: resources.join(", "),
                    });
                });
            });

            jSith.forEach(event.requirement, (_, requirement) => {
                const reqId = parseInt(requirement.requirement_id),
                    isBilled = !!pricingSetReqMap[reqId];

                requirementTable.push({
                    isBilled: isBilled ? "Yes" : "No",
                    eventId,
                    eventLocator,
                    requirementId: reqId,
                    uuid: `${eventId}&${reqId}`,
                    requirementName: requirement.requirement_name,
                    requirementCount: requirement.requirement_count,
                    requirementComment: requirement.req_comment,
                });
            });
        });

        this.rsrvTable = rsrvTable;
        this.requirementTable = requirementTable;

        this.initTableConfigs();

        this.initData = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    initTableConfigs() {
        if (this.multiEvents) {
            this.occTableColumns.splice(1, 0, { id: "ref", header: "Reference" });
            this.reqTableColumns.splice(1, 0, { id: "ref", header: "Reference" });
        }

        if (this.multiProfiles) {
            this.occTableColumns.splice(
                this.multiEvents ? 4 : 3,
                0,
                { id: "segment", header: "Segment" },
                { id: "segComment", header: "Segment Comment" },
            );
        }

        const rsrvPromise = new Promise((resolve, _) => {
            resolve(this.rsrvTable);
        });

        const getRsrvData = async () => {
            const resData: any = await rsrvPromise;

            return {
                rows: resData.map(this.rsrvMapToRow),
            };
        };

        this.occTableConfig = {
            type: "unpaginated",
            dataSource: getRsrvData,
            columns: this.occTableColumns,
        };

        const reqPromise = new Promise((resolve, _) => {
            resolve(this.requirementTable);
        });

        const getReqData = async () => {
            const reqData: any = await reqPromise;

            return {
                rows: reqData.map(this.reqMapToRow),
            };
        };

        this.reqTableConfig = {
            type: "unpaginated",
            dataSource: getReqData,
            columns: this.reqTableColumns,
        };
    }

    @Bind
    rsrvMapToRow(rsrv: any): Table.Row {
        return {
            id: this.multiEvents ? `${rsrv.rsrvId}-${rsrv.eventId}` : rsrv.rsrvId,
            name: rsrv.eventName,
            selectable: rsrv.isBilled === "No",
            cells: {
                alreadyBilled: { text: rsrv.isBilled },
                ...(this.multiEvents && { ref: { text: rsrv.eventLocator } }),
                name: { text: rsrv.eventName },
                title: { text: rsrv.eventTitle },
                ...(this.multiProfiles && { segment: { text: rsrv.profileName } }),
                ...(this.multiProfiles && { segComment: { text: rsrv.profileComment } }),
                start: { text: rsrv.rsrvStartDate },
                end: { text: rsrv.rsrvEndDate },
                comment: { text: rsrv.rsrvComment },
                locations: { text: rsrv.locations },
                resources: { text: rsrv.resources },
            },
        };
    }

    @Bind
    reqMapToRow(req: any): Table.Row {
        return {
            id: this.multiEvents ? `${req.requirementId}-${req.eventId}` : req.requirementId,
            name: req.requirementName,
            cells: {
                isBilled: { text: req.isBilled },
                ...(this.multiEvents && { ref: { text: req.eventLocator } }),
                reqName: { text: req.requirementName },
            },
        };
    }

    async createPricingSet() {
        if (!this.name) {
            this.errorMessage = "Please enter a name for this pricing set";
            this.errorOutline = true;
            this.cd.detectChanges();
            return;
        }
        this.errorOutline &&= false;

        const included = this.rsrvTable?.filter((rsrv) => rsrv.included);

        let eventIds = included.map((res) => res.eventId);

        const rsrvIds = S25Util.array.unique(included.map((res) => res.rsrvId));

        const includedReq = this.requirementTable?.filter((req) => req.included);

        eventIds = eventIds.concat(includedReq.map((req) => req.eventId));

        const reqIds = S25Util.array.unique(includedReq.map((req) => req.requirementId));

        eventIds = S25Util.array.unique(eventIds);

        if (included.length || includedReq.length || this.includeEventType) {
            if (!eventIds.length) {
                eventIds = S25Util.array.unique(this.rsrvTable?.map((rsrv) => rsrv.eventId));

                if (!eventIds.length) {
                    this.errorMessage = "No events present";
                    this.cd.detectChanges();
                    return;
                }
            }

            this.isCreatingPricingSet = true;
            this.cd.detectChanges();
            S25LoadingApi.init(this.submitButton.nativeElement);

            try {
                TelemetryService.sendWithSub("Pricing", "Event", "CreateSet");
                const response = await PricingService.postBillingCustom(
                    this.name,
                    eventIds,
                    rsrvIds,
                    reqIds,
                    this.includeEventType.toString(),
                );

                this.modelBean.chosenPricingSet = response?.content?.data?.items[0];

                await this.modelBean.getPricingSets();

                this.modelBean.selectPricingSet();
                this.isCreatingPricingSet = false;

                this.close.emit();
            } catch (error) {
                this.errorMessage = S25Util.errorText(error);
                this.isCreatingPricingSet = false;
                this.cd.detectChanges();
                S25LoadingApi.destroy(this.submitButton.nativeElement);
            }
        } else {
            this.errorMessage =
                "Please select at least one occurrence or requirement, or select Include Event Type, to create a custom pricing set.";
            this.cd.detectChanges();
            return;
        }
    }

    include(rsrvIds: Set<number | string>, table: "rsrv" | "req") {
        const ids = Array.from(rsrvIds),
            tableType = table === "rsrv" ? "rsrvTable" : "requirementTable",
            idProp = table === "rsrv" ? "rsrvId" : "requirementId";

        this[tableType].forEach((obj: any) => {
            const selected = ids.find((id: number | string) => {
                if (typeof id === "string") {
                    // multiple events - extract eventId as well as propId
                    const objEventIdPair = id.split("-"),
                        objId = parseInt(objEventIdPair[0]),
                        eventId = parseInt(objEventIdPair[1]);

                    return obj[idProp] === objId && obj.eventId === eventId;
                }

                return obj[idProp] === id;
            });

            obj.included = !!selected;
        });

        this.showSelectDateRange = false;

        this.selectedDateRange = {
            start: new Date(),
            end: new Date(),
            placeholderStart: S25Datefilter.transform(new Date(), this.modelBean.dateFormat),
            placeholderEnd: S25Datefilter.transform(new Date(), this.modelBean.dateFormat),
            selectionMade: false,
        };

        this.cd.detectChanges();
    }

    toggleShowDateRange() {
        this.showSelectDateRange = !this.showSelectDateRange;

        if (!this.showSelectDateRange && !this.selectedDateRange.selectionMade) {
            this.clearDates();
        }

        this.cd.detectChanges();
    }

    updateDateRange(event: any, date: "start" | "end") {
        this.selectedDateRange[date] = event;

        this.selectedDateRange[date === "start" ? "placeholderStart" : "placeholderEnd"] = S25Datefilter.transform(
            event,
            this.modelBean.dateFormat,
        );

        this.cd.detectChanges();
    }

    applyDates() {
        if (S25Util.date.parse(this.selectedDateRange.start) > S25Util.date.parse(this.selectedDateRange.end)) {
            this.dateRangeErrorMsg = "Start date must be before end date";

            this.cd.detectChanges();

            return;
        } else {
            this.dateRangeErrorMsg = null;
        }

        const rsrvIds: any = this.rsrvTable
            .filter((rsrv: any) => {
                if (
                    rsrv.isBilled === "No" &&
                    S25Util.date.parse(rsrv.rsrvStartDate) < S25Util.date.toEndOfDay(this.selectedDateRange.end) &&
                    S25Util.date.parse(rsrv.rsrvEndDate) > S25Util.date.toStartOfDay(this.selectedDateRange.start)
                ) {
                    rsrv.included = true;
                    return rsrv;
                }

                rsrv.included = false;
            })
            .map((rsrv: any) => (this.multiEvents ? `${rsrv.rsrvId}-${rsrv.eventId}` : rsrv.rsrvId));

        this.selectedDateRange.selectionMade = true;

        this.selectedIds = new Set(rsrvIds);

        this.showSelectDateRange = false;

        this.cd.detectChanges();
    }

    clearDates() {
        this.showSelectDateRange = false;

        this.dateRangeErrorMsg = null;

        this.selectedDateRange = {
            start: new Date(),
            end: new Date(),
            placeholderStart: S25Datefilter.transform(new Date(), this.modelBean.dateFormat),
            placeholderEnd: S25Datefilter.transform(new Date(), this.modelBean.dateFormat),
            selectionMade: false,
        };

        this.selectedIds = new Set();

        this.rsrvTable?.map((rsrv: any) => (rsrv.included = false));

        this.cd.detectChanges();
    }
}
